<template>
  <div id="login-page">
    <div id="login-overlay">
      <v-dialog v-model="showDialog" max-width="600" persistent>
        <v-card>
          <v-card-text>
            <v-form @submit.prevent="login()">
              <v-container>
                <v-row>
                  <v-col style="padding-bottom: 0px">
                    <v-text-field
                      label="Username"
                      v-model="username"
                      type="text"
                      :disabled="loginLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col style="padding-bottom: 0px">
                    <v-text-field
                      label="Password"
                      v-model="password"
                      type="password"
                      :disabled="loginLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div style="color: red">{{ errorMessage }}</div>
                <v-row>
                  <v-col>
                    <v-btn
                      block
                      color="orange"
                      @click="login"
                      style="color: white"
                      :loading="loginLoading"
                      type="submit"
                      >Login</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
//db7925 - orange
export default {
  data() {
    return {
      showDialog: true,
      username: process.env.VUE_APP_TEST_USERNAME || "",
      password: process.env.VUE_APP_TEST_PASSWORD || "",
      loginLoading: false,
      errorMessage: "",
      logoUrl: 1,
      submitting: false,
    };
  },
  methods: {
    login() {
      if (this.loginLoading) return;
      this.loginLoading = true;
      this.errorMessage = "";
      this.$store
        .dispatch("loginUser", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          console.log(response);
          this.$router.push("store");
          this.loginLoading = false;
        })
        .catch((error) => {
          console.log("error", error);
          this.errorMessage = error || "Something went wrong";
          this.loginLoading = false;
        });
    },
  },
};
</script>

<style>
#login-page {
  background-image: url("~@/assets/straightcurve_login_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  opacity: 1;
}

#login-overlay {
  z-index: 1;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
  top: 0px;
  left: 0px;
  background: rgba(43, 23, 0, 0.8);
}

#logo {
  opacity: 1;
}
</style>